import { GlobalHoverService } from 'o365-vue-services';
import ObjectHover from 'assets.vue.components.objects.ObjectHover.vue';

export default {
    mounted(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        const value = binding.value;

        // Håndter både int og objekt
        const objectId = typeof value === 'object' ? value.objectId : value;
        const extraInfo = typeof value === 'object' && value.extraInfo ? value.extraInfo : null;

        service.bindElement(el, {
            component: ObjectHover,
            props: { 
                objectId,
                extraInfo,
            },
        });
    },
    updated(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        const props = service.getPropsFromEl(el)
        if (props) {
            const value = binding.value;
            const newObjectId = typeof value === 'object' ? value.objectId : value;
            const newExtraInfo = typeof value === 'object' && value.extraInfo ? value.extraInfo : null;

            // Oppdater props hvis verdiene er endret
            if (props.objectId !== newObjectId || props.extraInfo !== newExtraInfo) {
                props.objectId = newObjectId;
                props.extraInfo = newExtraInfo;
            }
        }
    },
    unmounted(el: HTMLElement, _binding: any) {
        const service = GlobalHoverService.getService();
        service.unbindElement(el);
    }
};